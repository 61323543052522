<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer l'utilisateur  : ${user.name}`
          : "Créer un utilisateur"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="user.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="user.email"
                    dense
                    label="Email"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="user.home_page_url"
                    dense
                    label="Page d'accueil"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex ">
                  <v-text-field
                    outlined
                    v-model="user.password"
                    dense
                    label="Mot de passe"
                    :rules="[optionalOnEdit]"
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    class="ml-3"
                    @click.prevent="generatePassword()"
                  >
                    Générer un mot de passe
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-select
                    v-model="user.role"
                    :items="rolesList"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Role"
                    :rules="[valueSelected]"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveUser"
                >
                  Enregistrer l'utilisateur</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'users-list' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, valueSelected } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "UserCreateOrUpdate",
  async created() {
    this.getRoles();
    if ("id" in this.$route.params) {
      this.getUserById(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
  },
  data() {
    return {
      required,
      valueSelected,
      editing: false,
      rolesList: [],
      user: {
        pk: -1,
        name: "",
        subject: "",
        action: "manage",
        password: "",
        description: "",
      },
      projectsList: [],
    };
  },
  methods: {
    async fetchData(url) {
      const { data } = await axios.get(url);
      return data.items;
    },
    async getRoles() {
      this.rolesList = await this.fetchData("/roles/");
    },
    optionalOnEdit(value) {
      return this.editing ? true : required(value);
    },
    async saveUser() {
      const isFormValid = this.$refs.form.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.user.name,
        email: this.user.email,
        role_id: this.user.role,
        home_page_url: this.user.home_page_url,
      };
      if (this.user.password) {
        payload.password = this.user.password;
      }
      if (this.editing) {
        const { status } = await axios.put(`/users/${this.user.pk}/`, payload);
        if (status === 200) {
          await router.push({ name: "users-list" });
        }
      } else {
        const { status } = await axios.post(`/users/`, payload);
        if (status === 201) {
          await router.push({ name: "users-list" });
        }
      }
    },
    async getUserById(id) {
      const { status, data } = await axios.get(`/users/${id}/`);
      if (status === 200) {
        this.user = {
          pk: data.id,
          name: data.name,
          email: data.email,
          password: "",
          role: data.role_id,
          home_page_url: data.home_page_url,
        };
      }
    },
    generatePassword(length = 12) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const password = Array.from(
        { length },
        () => characters[Math.floor(Math.random() * characters.length)]
      ).join("");
      this.user.password = password;
    },
  },
};
</script>

<style scoped></style>
